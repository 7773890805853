/* https://codepen.io/AsLittleDesign/pen/ZbVVwa */

.container {
  /* height: 100vh;
  width: 100vw; */
  font-family: Helvetica;
  position: fixed;
  bottom: 50vh;
  left: calc(50vw - 125px);
  z-index: 2000;
}

.loader {
  height: 20px;
  width: 250px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.loader--dot {
  animation-name: loader;
  animation-timing-function: ease-in-out;
  animation-duration: 2300ms;
  animation-iteration-count: infinite;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background-color: black;
  position: absolute;
  border: 2px solid white;
}
.loader--dot:first-child {
  /* background-color: #8cc759; */
  background-color: #c5cae9;
  animation-delay: 0.5s;
}
.loader--dot:nth-child(2) {
  /* background-color: #8c6daf; */
  background-color: #9fa8da;
  animation-delay: 0.4s;
}
.loader--dot:nth-child(3) {
  /* background-color: #ef5d74; */
  background-color: #7986cb;
  animation-delay: 0.3s;
}
.loader--dot:nth-child(4) {
  /* background-color: #f9a74b; */
  background-color: #5c6bc0;
  animation-delay: 0.2s;
}
.loader--dot:nth-child(5) {
  /* background-color: #60beeb; */
  background-color: #3f51b5;
  animation-delay: 0.1s;
}
.loader--dot:nth-child(6) {
  /* background-color: #fbef5a; */
  background-color: #3949ab;
  animation-delay: 0s;
}
.loader--text {
  position: absolute;
  top: 200%;
  left: 0;
  right: 0;
  width: 4rem;
  margin: auto;
}
.loader--text:after {
  content: "Loading";
  font-weight: bold;
  animation-name: loading-text;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

@keyframes loader {
  15% {
    transform: translateX(0);
  }
  45% {
    transform: translateX(230px);
  }
  65% {
    transform: translateX(230px);
  }
  95% {
    transform: translateX(0);
  }
}
@keyframes loading-text {
  0% {
    content: "Loading";
  }
  25% {
    content: "Loading.";
  }
  50% {
    content: "Loading..";
  }
  75% {
    content: "Loading...";
  }
}
